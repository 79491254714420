import { t } from '@lingui/macro'
import { baseCommonStore } from '@/store/common'
import { isCommon } from '@/helper/common'
import { oss_svg_image_domain_address } from '../oss'

/* *******下载模块 homedownload ******* */
/*
类名样式枚举
app-img 移动端图片样式
desktop 台式电脑样式
notebook mac 笔记本样式
notebook linux 笔记本样式
*/
export enum ClassNameObjEnum {
  'iPhone' = 'app-img', // 苹果手机图标
  'Android' = 'app-img', // 安卓手机图标
  'Windows' = 'desktop', // windows 图标
  'Mac' = 'mac-notebook', // mac 笔记本图标
  'Linux' = 'linux-notebook', // linux 图标
}

/*
下载图标类型
*/
export type IconItemType = {
  name: string // 名称
  img: string // 图标
  lastVersion: string // 最新版本
  supportVersion: string // 支持版本
  onlySupport: string // 仅支持版本
  contents: Array<string> // 内容描述
  downLoadStoreText: string // 下载按钮文字
  url: Array<string> // 图片地址
  downLoadStoreImg: string // 下载按钮图标
  appStatusInd: number
}

export enum downEnum {
  appStore = 'ios',
  android = 'android',
  windows = 'windows',
  google = 'google',
  linux = 'linux',
  mac = 'mac',
}
export enum onOFFemum {
  on = 1,
  off = 2,
}
export interface IAppStatusEnum {
  appStoreStatus?: number
  windowsStatus?: number
  macStatus?: number
  androidStatus?: number
  linuxStatus?: number
  unknow?: number
}
export const appItemHandle = (appItem?: Array<appItem>) => {
  let appStatusInds: IAppStatusEnum = {
    appStoreStatus: onOFFemum.off,
    windowsStatus: onOFFemum.off,
    macStatus: onOFFemum.off,
    androidStatus: onOFFemum.off,
    linuxStatus: onOFFemum.off,
    unknow: onOFFemum.off,
  }
  appItem?.forEach(item => {
    switch (item.appTypeCd) {
      case downEnum.appStore:
        appStatusInds.appStoreStatus = item.appStatusInd
        break
      case downEnum.android:
        appStatusInds.androidStatus = item.appStatusInd
        break
      case downEnum.windows:
        appStatusInds.windowsStatus = item.appStatusInd
        break
      case downEnum.mac:
        appStatusInds.macStatus = item.appStatusInd
        break
      case downEnum.linux:
        appStatusInds.linuxStatus = item.appStatusInd
        break
      default:
        appStatusInds.unknow = 0
    }
  })
  return appStatusInds
}
export enum IconListsNameEnum {
  iPhone = 'iPhone',
  android = 'Android',
  windows = 'Windows',
  linux = 'Linux',
  mac = 'Mac',
}
export enum IconListsIndexSelectEnum {
  iPhone = 0,
  android = 1,
  windows = 2,
  linux = 3,
  mac = 4,
  default = -1,
}
// 下载图标 lists
export const iconLists = (appItem?: Array<appItem>): IconItemType[] => {
  const appStatusInds = appItemHandle(appItem)
  // const localePngWelcome = `welcome_${baseCommonStore.getState().locale.slice(-2)}${isCommon() ? '_com' : ''}.png`
  // const localePngConnect = `vpn_connect_${baseCommonStore.getState().locale.slice(-2)}${isCommon() ? '_com' : ''}.png`
  const imageSuffix = `${baseCommonStore.getState().locale.slice(-2)}${isCommon() ? '_com' : ''}`
  const downLoadImageList = {
    localePngWelcome: `welcome_${imageSuffix}.png`,
    localePngConnect: `vpn_connect_${imageSuffix}.png`,
    vpnWindowImage: `vpn_window_${imageSuffix}.png`,
    vpnMacImage: `vpn_mac_${imageSuffix}.png`,
    vpnLinuxImage: `vpn_linux_${imageSuffix}.png`,
  }
  return [
    {
      name: IconListsNameEnum.iPhone,
      img: 'icon_apple',
      lastVersion: '1255',
      supportVersion: 'iOS 12',
      onlySupport: '',
      contents: [
        t`constants_homepage_index_6yijd9vqlf`,
        t`constants_homepage_index_1yquozk9wn`,
        t`constants_homepage_index_ko2wgpmkeo`,
        t`constants_homepage_index_r7xxlgb7sr`,
      ],
      downLoadStoreText: 'App Store',
      url: [downLoadImageList.localePngWelcome, downLoadImageList.localePngConnect],
      downLoadStoreImg: 'icon_apple',
      appStatusInd: appStatusInds.appStoreStatus || onOFFemum.off,
    },
    {
      name: IconListsNameEnum.android,
      img: 'icon_android',
      lastVersion: '1270',
      supportVersion: t`constants_homepage_index_r8jzwtvcm5`,
      onlySupport: '',
      contents: [
        t`constants_homepage_index_6yijd9vqlf`,
        t`constants_homepage_index_1yquozk9wn`,
        t`constants_homepage_index_ko2wgpmkeo`,
        t`constants_homepage_index_r7xxlgb7sr`,
      ],
      downLoadStoreText: 'Google Play',
      url: [downLoadImageList.localePngWelcome, downLoadImageList.localePngConnect],
      downLoadStoreImg: 'icon_google_play',
      appStatusInd: appStatusInds.androidStatus || onOFFemum.off,
    },
    {
      name: IconListsNameEnum.windows,
      img: 'icon_windows',
      lastVersion: '125',
      supportVersion: t`constants_homepage_index_ccbydehcnx`,
      onlySupport: '',
      contents: [
        t`constants_homepage_index_6yijd9vqlf`,
        t`constants_homepage_index_1yquozk9wn`,
        t`constants_homepage_index_ko2wgpmkeo`,
        t`constants_homepage_index_r7xxlgb7sr`,
      ],
      downLoadStoreText: '',
      url: [downLoadImageList.vpnWindowImage],
      downLoadStoreImg: '',
      appStatusInd: appStatusInds.windowsStatus || onOFFemum.off,
    },
    {
      name: IconListsNameEnum.mac,
      img: 'icon_mac',
      lastVersion: '125',
      supportVersion: t`constants_homepage_index_dnb4xtc93w`,
      onlySupport: '',
      contents: [
        t`constants_homepage_index_6yijd9vqlf`,
        t`constants_homepage_index_1yquozk9wn`,
        t`constants_homepage_index_ko2wgpmkeo`,
        t`constants_homepage_index_r7xxlgb7sr`,
      ],
      downLoadStoreText: '',
      url: [downLoadImageList.vpnMacImage],
      downLoadStoreImg: '',
      appStatusInd: appStatusInds.macStatus || onOFFemum.off,
    },
    {
      name: IconListsNameEnum.linux,
      img: 'icon_linux',
      lastVersion: '125',
      supportVersion: t`constants_homepage_index_atza0srizw`,
      onlySupport: t`constants_homepage_index_rnpq8mknnn`,
      contents: [
        t`constants_homepage_index_6yijd9vqlf`,
        t`constants_homepage_index_1yquozk9wn`,
        t`constants_homepage_index_ko2wgpmkeo`,
        t`constants_homepage_index_r7xxlgb7sr`,
      ],
      downLoadStoreText: '',
      url: [downLoadImageList.vpnLinuxImage],
      downLoadStoreImg: '',
      appStatusInd: appStatusInds.linuxStatus || onOFFemum.off,
    },
  ]
}

/* *******首页 header******* */

// 安全访问内容
export const safeLists = () => [
  t`constants_homepage_index_xtziixqgxn`,
  t`constants_homepage_index_4zl4wrfxye`,
  t`constants_homepage_index_w5d4zbqqrk`,
  t`constants_homepage_index_83jyx6nrww`,
  t`constants_homepage_index_f9r6iy9zpp`,
]

// 安全访问内容小图标
export const iconNameLists = () => [
  'icon_netflix',
  'icon_fox',
  'icon_line_tv',
  'icon_youtube',
  'icon_google',
  'icon_instagram',
  'icon_spotify',
  'icon_skype',
  'icon_linkedin',
  'icon_discord',
  'icon_whatsapp',
]

/* *******首页 selectreason ******* */

// 左侧理由内容
export const leftContent = () => [
  {
    img: `${oss_svg_image_domain_address}vpn/secure.png`,
    iconName: 'icon_safety',
    title: t`constants_homepage_index_gvhhhxrqna`,
    content: t`constants_homepage_index_3f7nezy_yp`,
  },
  {
    img: `${oss_svg_image_domain_address}vpn/unlock.png`,
    iconName: 'icon_unlock',
    title: t`constants_homepage_index_i71hhxviyx`,
    content: t`constants_homepage_index_qggv4gmx65`,
  },
]

// 右侧理由内容
export const rightContent = () => [
  {
    img: `${oss_svg_image_domain_address}vpn/high_speed.png`,
    iconName: 'icon_high_speed',
    title: t`constants_homepage_index_8ioycf3ey7`,
    content: t`constants_homepage_index_fl6juiin8k`,
  },
  {
    img: `${oss_svg_image_domain_address}vpn/omnibearing.png`,
    iconName: 'icon_service',
    title: t`constants_homepage_index_cxhfuzhmus`,
    content: t`constants_homepage_index_16sb3tqpfd`,
  },
]

/* *******首页 customervoice ******* */
// 用户列表
export const customerLists = (businessName: string) => [
  [
    {
      name: 'Hanoch Li',
      headImg: 'vpn_user_lv_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_b4z1hgmnti',
        values: { 0: businessName },
      }),
    },
    {
      name: t`constants_homepage_index_hki3ycvrep`,
      headImg: 'vpn_ct_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_hlg69chalb',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Traffic King',
      headImg: 'vpn_fz_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_n2ldsjly9z',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Stephen',
      headImg: 'vpn_lt_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_gr3ckmxenv',
        values: { 0: businessName },
      }),
    },
  ],
  [
    {
      name: 'Jocker',
      headImg: 'vpn_user_lv_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_b4z1hgmnti',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Tyler',
      headImg: 'vpn_ct_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_hlg69chalb',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Bill',
      headImg: 'vpn_fz_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_n2ldsjly9z',
        values: { 0: businessName },
      }),
    },
    {
      name: 'John',
      headImg: 'vpn_lt_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_gr3ckmxenv',
        values: { 0: businessName },
      }),
    },
  ],
]
// 用户列表 2
export const customerListss = (businessName: string) => [
  [
    {
      name: 'Hanoch Li',
      headImg: 'vpn_user_lv_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_b4z1hgmnti',
        values: { 0: businessName },
      }),
    },
    {
      name: t`constants_homepage_index_hki3ycvrep`,
      headImg: 'vpn_ct_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_hlg69chalb',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Traffic King',
      headImg: 'vpn_fz_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_n2ldsjly9z',
        values: { 0: businessName },
      }),
    },
  ],
  [
    {
      name: 'Stephen',
      headImg: 'vpn_lt_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_gr3ckmxenv',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Jocker',
      headImg: 'vpn_user_lv_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_b4z1hgmnti',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Tyler',
      headImg: 'vpn_ct_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_hlg69chalb',
        values: { 0: businessName },
      }),
    },
  ],
  [
    {
      name: 'Bill',
      headImg: 'vpn_fz_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_n2ldsjly9z',
        values: { 0: businessName },
      }),
    },
    {
      name: 'John',
      headImg: 'vpn_lt_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_gr3ckmxenv',
        values: { 0: businessName },
      }),
    },
    {
      name: 'Hanoch Li',
      headImg: 'vpn_user_lv_white',
      score: 5,
      content: t({
        id: 'constants_homepage_index_b4z1hgmnti',
        values: { 0: businessName },
      }),
    },
  ],
]

/* *******账户 帮助中心 ******* */

export enum equipmentEnum {
  /** apple */
  Apple = '0',
  /** android */
  Android = '1',
  /** window */
  Window = '2',
  /** mac */
  Mac = '3',
  /** linux */
  Linux = '4',
}
/* *******用户信息 ******* */

// 分页对象类型
export type PaginationType = {
  sizeCanChange: boolean // 是否分页
  showTotal: boolean // 是否显示总数
  total: number // 总条数
  pageSize: number // 每页条数
  current: number // 当前页
  pageSizeChangeResetCurrent: boolean // 页面刷新是否显示第一页
}

// 各平台图标 name
export enum SpaceIconEnum {
  'IOS' = 'icon_apple', // IOS 苹果图标
  'Android' = 'icon_android', // android 图标
  'Windows' = 'icon_windows', // windows 图标
  'MAC' = 'icon_mac', // mac 笔记本图标
  'Linux' = 'icon_linux', // linux 图标
}

// 复制邀请码或链接
export enum CopyTypeEnum {
  inviteCode = 'INVITE_CODE', // 复制邀请码
  address = 'ADDRESS', // 复制网页链接
}

// 获取有效剩余时间 firstDate：注册时间   secondDate：到期时间
export const getDates = secondDate => {
  const currentDay = Date.now() // 当天日期毫秒时间戳
  const currentMillisecond = 24 * 3600 * 1000 // 一天毫秒数
  if (secondDate) {
    const days = secondDate - currentDay // 获取会员有效剩余时间时间戳
    return Math.floor(days / currentMillisecond)
  }
  return 0
}

/* *******软件下载 枚举值 ******* */
export enum DownloadsEnum {
  Ios = 'ios',
  Android = 'android',
  WindowsLowversion = 'windowslowversion',
  WindowsHighversion = 'windowshighversion',
  Google = 'google',
  Linux = 'linux',
  Mac = 'mac',
}

/* *******软件下载 型号类型枚举值 ******* */
export enum ModelTypeEnum {
  Android = 'Android',
}

// 下载列表数据类型
export type appItem = {
  appStatusInd: number
  lanTypeCd: string // 语言类型
  downloadUrl: string // 下载地址
  description: string // 描述信息
  appTypeCd: string // 下载类型
}
